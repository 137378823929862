var body = document.body
var navigation = document.querySelectorAll('.sgi-nav__menu');

//Hamburger Menu Button
document.addEventListener('DOMContentLoaded', function() {

  var toggleBtn = document.querySelector('.sgi-nav__button')
  var hamburgerIcon = document.querySelector('.sgi-nav__button--hamburger')

  if(toggleBtn) {
    toggleBtn.addEventListener('click', function() {
      body.classList.toggle('sgi-nav--active')
      hamburgerIcon.classList.toggle('active')
      var ariaLabel = toggleBtn.ariaLabel;
      toggleBtn.ariaLabel = (ariaLabel == 'Open Menu') ? 'Close Menu' : 'Open Menu'
    })
  }
  
  var searchInput = document.getElementById('sgi-header__search-input');
  var searchInputClear = document.getElementById('sgi-header__search-input-clear');

  searchInput.addEventListener('input', function() {
    if(searchInput.value != ""){
      searchInputClear.classList.remove("hidden");
    }
    else {
      searchInputClear.classList.add("hidden");
    }
  });
  
  searchInputClear.addEventListener('click', function() {
    searchInput.value = "";
    searchInputClear.classList.add("hidden");
  });


  var menuItemHasChildren = document.querySelectorAll('.menu-item-has-children')

  for (var i = 0; i < menuItemHasChildren.length; i++) {

    menuItemHasChildren[i].addEventListener('mouseenter', function(e) {

      if (!body.classList.contains('sgi-nav--active')) {
        
        e.target.classList.add('current-menu-item')

      } 
    })

    menuItemHasChildren[i].addEventListener('mouseleave', function(e) {

      if (!body.classList.contains('sgi-nav--active')) {
      
        e.target.classList.remove('current-menu-item')

      } 
    })
    
    menuItemHasChildren[i].onclick = function(e) {

      if (body.classList.contains('sgi-nav--active')) {

        e.stopPropagation()
        e.target.parentElement.classList.toggle('current-menu-item')
        
      }
    }
  }

})

//Accessibility and Menu Functionality 
Array.prototype.slice.call(navigation).forEach(function (menuElements) {

  var allowMultiple = menuElements.hasAttribute('data-allow-multiple');
  var allowToggle = true

  menuElements.addEventListener('click', function (event) {
    var target = event.target;

    if (target.nodeName === 'A') {
      target = target.parentElement
    }

    if(target.classList.contains('sgi-nav--has-children') && target.children[0].href == '#'){
      event.preventDefault();
    }

    if (target.classList.contains('sgi-nav--has-children')) {

      var isExpanded = target.children[0].getAttribute('aria-expanded') === 'true';
      var active = menuElements.querySelector('[aria-expanded="true"]');

      if (!allowMultiple && active && active !== target.children[0]) {
        active.setAttribute('aria-expanded', 'false');
        target.classList.remove('active');

        if (!allowToggle) {
          active.removeAttribute('aria-disabled');
        } 
      }

      if (!isExpanded) {
        target.children[0].setAttribute('aria-expanded', 'true');
        target.classList.add('active');

        if (!allowToggle) {
          target.children[0].setAttribute('aria-disabled', 'true');
        }

      } else if (allowToggle && isExpanded) {
        target.children[0].setAttribute('aria-expanded', 'false');
        target.classList.remove('active');
      }
    }
  });
});